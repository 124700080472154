<div class="h5-search">
  <div class="page-title">
    <span class="wrap">
      <i class="icon-search"></i><span>{{ 'search' | translate }}</span>
    </span>
    <i class="close-btn icon-close-simple mutual-opacity onact96-not" (click)="close()"></i>
  </div>
  <ng-container *ngTemplateOutlet="searchMain"></ng-container>
</div>
<!-- 搜索主体 -->
<ng-template #searchMain>
  <div class="container">
    <search-game-base
      [labelCodes]="labelCodes"
      [searchValue]="searchValue"
      [maxHeight]="'none'"
      (beforeClickItem)="close()"
    ></search-game-base>
  </div>
</ng-template>
