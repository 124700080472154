import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'search-game-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class SearchGameDialogComponent implements OnInit {
  public constructor(
    @Optional() private dialogRef: MatDialogRef<SearchGameDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      searchValue: string;
      labelCodes: string[];
    },
  ) {
    if (data?.searchValue) {
      this.searchValue = data.searchValue;
    }
    if (data?.labelCodes) {
      this.labelCodes = data.labelCodes;
    }
  }

  public ngOnInit(): void {}

  public labelCodes: string[] = [];

  public searchValue: string = '';

  public close() {
    this.dialogRef?.close();
  }
}
